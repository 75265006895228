<template>
  <div class="Awarp">
    <dashCard
      class="powerBox21"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox21_cost','当前电能费用', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <div class="display-item">
          <div v-if="searchObj.TYPE === '按年'">{{commonLangHandler('powerBox21_thisYear','今年总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按月'">{{commonLangHandler('powerBox21_thisMonth','本月总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按周'">{{commonLangHandler('powerBox21_thisWeek','本周总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按天'">{{commonLangHandler('powerBox21_today','今天总费用', getZEdata)}}</div>
          <div>{{ toThousandsSeparator(detailInfo.cost1) }}<font>{{commonLangHandler('powerBox21_yuan','元', getZEdata)}}</font></div>
          <div>
            <i
              class="lowcode"
              :class="
                detailInfo.comparePercent1 >= 0
                  ? 'icon-shang shangColor'
                  : 'icon-xia xiaColor'
              "
            >
            </i>
            <font
              :class="
                detailInfo.comparePercent1 >= 0 ? 'shangColor' : 'xiaColor'
              "
              >{{ Math.abs(detailInfo.comparePercent1) || 0 }} %</font
            >
            <font>{{commonLangHandler('chuneng2_yoy','较去年', getZEdata)}}</font>
          </div>
        </div>
        <div class="display-item" v-if="checked">
          <div v-if="searchObj.TYPE === '按年'">{{commonLangHandler('powerBox21_lastYear','上年总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按月'">{{commonLangHandler('powerBox21_thisMonthLastYear','上年本月总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按周'">{{commonLangHandler('powerBox21_thisWeekLastYear','上年本周总费用', getZEdata)}}</div>
          <div v-if="searchObj.TYPE === '按天'">{{commonLangHandler('powerBox21_todayLastYear','上年今天总费用', getZEdata)}}</div>
          <div>{{ toThousandsSeparator(detailInfo.cost2) }}<font>{{commonLangHandler('powerBox21_yuan','元', getZEdata)}}</font></div>
          <div>
            <i
              class="lowcode"
              :class="
                detailInfo.comparePercent2 >= 0
                  ? 'icon-shang shangColor'
                  : 'icon-xia xiaColor'
              "
            >
            </i>
            <font
              :class="
                detailInfo.comparePercent2 >= 0 ? 'shangColor' : 'xiaColor'
              "
              >{{ Math.abs(detailInfo.comparePercent2) || 0 }} %</font
            >
            <font v-if="searchObj.TYPE === '按年'">{{commonLangHandler('chuneng2_yoy','较去年', getZEdata)}}</font>
          </div>
        </div>
      </div>
      <div class="card-content2">
        <a-switch
          :checked-children="commonLangHandler('powerBox22_yoy','同比', getZEdata)"
          :un-checked-children="commonLangHandler('powerBox22_yoy','同比', getZEdata)"
          v-model="checked"
          @change="checkedChange"
        />
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <powerBox21
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerBox21",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      checked: false,
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      gridLeft: "",
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF", "#FF841F"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    checkedChange() {
      //console.log(this.checked);
      this.initEchart();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    //每月报警预警数
    getData() {
      // this.detailInfo = {
      //     cost1: '51276.33',
      //     cost2: '5270.33',
      //     comparePercent1: -11.2,
      //     comparePercent2: 11.2,
      // }
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
      };
      Object.assign(data, this.transformSearch());
      if (this.searchObj.TYPE == "按天") {
        data.type = "day15m";
        data.queryType = data.type;
        delete data.type;
      } else {
        data.queryType = data.type;
        delete data.type;
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/cost",
        method: "post",
        data: data,
      })
        .then((res) => {
          let result = res.data.data;
          // this.detailInfo = {}
          this.detailInfo.cost1 = result.cost1;
          this.detailInfo.cost2 = result.cost2;
          this.detailInfo.comparePercent1 = result.comparePercent1;
          this.detailInfo.comparePercent2 = result.comparePercent2;
          //console.log(result);
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = "8%";
          } else {
            this.gridLeft = "1%";
          }
          
          this.initEchart();
          this.$forceUpdate();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      let a = [0, 0, 0, 0]
      let lang = sessionStorage.getItem("locale") || 'CH'
      if(lang=='EN'){
         a = [0, 0, 0, 120];
      }
      let vm = this;
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          smooth: false,
          emphasis: {
            focus: "series",
          },

          // barGap:0,
          barWidth: 10,
        };
        series.push(obj);
      });
      //console.log(!this.checked ? [series[0]] : series);
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: this.gridLeft,
          right: "3%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "10%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // formatter: function(params, ticket, callback) {
          //   //console.log(params)
          //   let str = ""
          //   if(vm.searchObj.TYPE === "按年"){
          //     str = params[0].name.split(".")[1] + "月" + "<br/>"
          //   }else{
          //     str = params[0].name + "<br/>"
          //   }
          //   params.forEach(item=>{
          //     let a = 0
          //     if(Number(item.value)>9999){
          //       a = (item.value/10000).toFixed(2) + "万"
          //     }else{
          //       a = item.value
          //     }
          //
          //     str += item.seriesName + "<span style='display: inline-block;margin-right: 20px'></span>" + a + "<br/>"
          //   })
          //   return str
          // }
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          type: "value",
          name: this.commonLangHandler('powerBox21_totalCost','总费用(元)', this.getZEdata),
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
            padding: a,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        // 控住柱状图样式
        series: !this.checked ? [series[0]] : series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.powerBox21 {
  .card-content1 {
    padding-left: 0px;
    // height: 68px;
    width: 100%;

    .display-item {
      height: inherit;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      margin-right: 10px;
      background: #fafafa;
      padding: 10px;
      border-radius: 10px;
      div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        &:nth-child(2) {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;

          font {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            margin-left: 6px;
          }
        }

        &:nth-child(3) {
          i {
            font-size: 12px;
          }

          font {
            font-size: 12px;
            font-weight: 400;
            color: #9f9f9f;
            line-height: 20px;
            margin-right: 6px;
          }

          .shangColor {
            color: #f53f3f;
          }

          .xiaColor {
            color: #0ca919;
          }
        }
      }
    }
  }

  .card-content2 {
    flex: 1;
    height: 100%;
    position: relative;

    .ant-switch {
      position: absolute;
      top: 10px;
      right: 1px;
      z-index: 1;
    }

    .ant-switch-checked {
    }

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
